import _xhr from "./documentLoaders/xhr";
var exports = {};
const xhrLoader = _xhr;
const api = {};
exports = api;

/**
 * Setup browser document loaders.
 *
 * @param jsonld the jsonld api.
 */
api.setupDocumentLoaders = function (jsonld) {
  if (typeof XMLHttpRequest !== "undefined") {
    jsonld.documentLoaders.xhr = xhrLoader;
    // use xhr document loader by default
    jsonld.useDocumentLoader("xhr");
  }
};

/**
 * Setup browser globals.
 *
 * @param jsonld the jsonld api.
 */
api.setupGlobals = function (jsonld) {
  // setup browser global JsonLdProcessor
  if (typeof globalThis.JsonLdProcessor === "undefined") {
    Object.defineProperty(globalThis, "JsonLdProcessor", {
      writable: true,
      enumerable: false,
      configurable: true,
      value: jsonld.JsonLdProcessor
    });
  }
};
export default exports;